@media (min-width: 2000px){
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      max-width: 1780px !important;
  }
  }


body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafdff !important;
  color: #363f5e;
}

.state_list {
  .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 !important;
  }
  .css-1h51icj-MuiAutocomplete-root
    .MuiAutocomplete-inputRoot
    .MuiAutocomplete-input {
    min-width: 132px !important;
    border-color: transparent !important;
  }
}
.css-18nc3u2 .MuiOutlinedInput-root{
  padding: 0 !important;
}

/*button styles */

.btn-outline-dark {
  color: #60abfb;
  border-color: #60abfb;
  &:hover {
    background-color: #60abfb !important;
    border-color: #60abfb !important;
  }
}
.btn-primary {
  background-color: #60abfb !important;
  border-color: #60abfb !important;
}
