.comments-head-section{
    span{
        font-size: 18px;
        font-weight: bold;
        color: #363f5e;
    }
}

.comments-list-item{
    margin-top: 100px;
}