.header {
  margin-top: 78px;
  button{
    width: 152px;
  }
}
.dropDown-bar {
  background-color: #e8f7ff;
  border-radius: 8px;
  border-color: #e3e3e3;
}

.service_request_table_row .seqrops-instance-table-wrapper {
  height: calc(100vh - 264px) !important;
}
