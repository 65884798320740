.serviceRequest {
  margin-top: 100px;
}

.header {
  margin-top: 78px;
}
.serivceRequest-add {
  background-color: white !important;
  border: 1px solid #e3e3e3 !important;
  border-radius: 10px;
  width: 100%;
  padding: 64px;
}

.serviceRequest-ticket {
  margin-left: 20px;
  background: #fff9f9;
  text-align: center;
  border: 1px solid #fff1f1;
  border-radius: 6px;
  text-align: left;
  padding: 40px 40px !important;
  h4 {
    color: #6b7a99;
    font-weight: bold;
  }
  img {
    width: 160px;
  }
}
.dropDown-bar {
  padding: 20px !important;
  border: 1px solid #d6e7fa;
  background: #e8f7ff !important;
  p {
    margin: 0;
  }
}

.priority_list_dropdown {
  .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 !important;
  }
  .css-1h51icj-MuiAutocomplete-root
    .MuiAutocomplete-inputRoot
    .MuiAutocomplete-input {
    min-width: 100% !important;
    width: 100%;
  }
}
.Equipment_dropdown {
  .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 !important;
  }
  .css-1h51icj-MuiAutocomplete-root
    .MuiAutocomplete-inputRoot
    .MuiAutocomplete-input {
    min-width: 100% !important;
    width: 100%;
  }
}

.Equipment_Model_dropdown {
  .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 !important;
  }
  .css-1h51icj-MuiAutocomplete-root
    .MuiAutocomplete-inputRoot
    .MuiAutocomplete-input {
    min-width: 100% !important;
    width: 100%;
  }
}

.serviceType_list_dropdown {
  .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 !important;
  }
  .css-1h51icj-MuiAutocomplete-root
    .MuiAutocomplete-inputRoot
    .MuiAutocomplete-input {
    min-width: 100% !important;
    width: 100%;
  }
}

.AssignTo_dropdown {
  .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 !important;
  }
  .css-1h51icj-MuiAutocomplete-root
    .MuiAutocomplete-inputRoot
    .MuiAutocomplete-input {
    min-width: 100% !important;
    width: 100%;
  }
}

.Equipment_dropdown {
  width: 27%;
}
.Customer_dropdown {
  width: 27%;
  .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 !important;
  }
  .css-1h51icj-MuiAutocomplete-root
    .MuiAutocomplete-inputRoot
    .MuiAutocomplete-input {
    min-width: 100% !important;
    width: 100%;
  }
}

.description {
  width: 100%;
  margin-right: 10px;
  textarea {
    height: 142px;
    resize: none;
  }
}
.img_preview {
  img {
    border: 1px solid #eee;
    border-radius: 4px;
    object-fit: cover;
  }
}
.img_innerBox {
  position: relative;
  img:last-child {
    border: 1px solid white;
    position: absolute;
    width: 32px;
    right: 1px;
    background: white;
    border-radius: 100%;
  }
}

.header {
  button {
    width: 137px;
  }
}

.FixWithin_dropdown {
  width: 275px;

  .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 !important;
  }
  .css-1h51icj-MuiAutocomplete-root
    .MuiAutocomplete-inputRoot
    .MuiAutocomplete-input {
    min-width: 100% !important;
    width: 100%;
  }
}
