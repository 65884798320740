

.seqrops-navbar-wrapper{
    padding: 5px;
    box-shadow: 0px 4px 16px rgba(0,0,0,0.06);
    background-color: #fff;
    margin-bottom: 10px;
}

.seqrops-logo-section{
    display: flex;
    justify-content: flex-start;
}

.seqrops-settings-icon-section, .seqrops-user-section {
    display: flex;
    justify-content: flex-end;

    button{
        border: none;
        padding: 10px;
        img{
            width: 27px;
        }
    }
    p{
        margin: 11px 8px;
    }
}

.seqrops-navbar-section {
    background: white;
}

.seqrops-navbar-wrapper {

    .seqrops-logo-section {
        img {
            width: 360px;
        }
    }
    .seqrops-settings, .seqrops-management-log-out-section {
        background: transparent;
    }
}

.seqrops-management-log-out-section{
    button{
        background: none;
    }
}

.seqrops-asset-apps{
    img{
        width: 4vh;
        margin: 20px 20px;
    }
}

.header-sidebar{
    span{
        font-size: 12px;
        font-weight: bolder;
    }

    img{
        width: 2vh;
        margin: 10px 10px;
    }

    h4{
      text-transform: capitalize;
      font-weight: bold;
      font-size: 14px;
    }
    border-bottom: 1px solid #eee !important;
  margin-bottom: 20px;
  }
  
.seqrops-asset-apps-row{
    flex-direction: row;
 } 

 .target-app-container{
    padding: 10px;
    margin: 10px;
    text-align: center;
    background-color: #E8F7FF;
    border: 1px solid #D9F3FE;
    span{
        font-size: 12px;
        display: block;
    }
    a{
        text-decoration: none;
        color: #232323;
    }
 }

 .target-app-container:hover{
    background-color: rgb(202, 224, 240);

    a{
        text-decoration: none;
        color: #232323;
    }
 }

 .p-overlaypanel-content{
    width: 30vw;
 }

 .p-overlaypanel{
    margin-top: 10px;
 }

 .seqrops-settings {
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease;
  
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  
    &:hover {
      transform: scale(1.2);
    }
  }

  .logout-section {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .logout-section span {
    margin-right: 5px;
    color: black; 
    transition: color 0.3s ease;
  }
  
  .logout-section img {
    width: 20px;
    height: 20px;
    transition: transform 0.3s ease;
  }
  
  .logout-section:hover img {
    transform: scale(1.4);
    }

.target-app-container:hover .div img {
  transform: scale(1.4); /* Zoom effect */
}

.target-app-container .div img {
  transition: transform 0.3s ease;
}