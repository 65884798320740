.comment-list-item-wrapper {

    background-color: white;
    padding: 13px 20px;
    margin-top: 10px;
    border: 1px solid #e9e9e9;
    border-radius: 21px;
    color: #363f5e;

    .container-fluid {
      .row:first-child {
        display: flex;
        justify-content: space-between;
  
        .username {
          order: 1;
          display: flex;
          justify-content: flex-start;

          span{
            font-weight: bold;
          }
          p{
            margin: 0;
          }
        }
  
        .created-date {
          order: 2;
          display: flex;
          justify-content: flex-end;
        }

        span{
            font-size: 14px;
        }
      }
      
      .comment-area{
        p{
            font-size: 14px;
            margin-bottom: 0;
        }
      }
    }
  }

  .comment-area {
    word-break: break-all;
}
//   .comment-list-item-wrapper:hover {
//     background-color: rgb(202, 224, 240);
//   }