.myTable th,
td {
  border: 1px solid rgb(196, 192, 192);
  color: rgb(88, 86, 86);
  padding: 15px;
  text-align: left;
}
.instance-no-result {
  td {
    text-align: center;
  }
}

.operations {
  width: 30px;
  span{
    margin-right: 5px;
    img{
      width: 17px;
    }

  }
}
.seqrops-instance-table tr td {
  max-width: 400px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.seqrops-instance-table table th {
  background: #f1f8ff;
  top: -2px;
  position: sticky;
}
::ng-deep{
  .seqrops-instance-table {
    height: calc(100vh - 100px) !important;
  }
  
}
tr:hover {
  background-color: #e8f7ff;
  cursor: pointer; 
}

.redIndicator{

  background-color: red;
  width: 11px;
  height: 11px;
  display: inline-block;
  border-radius: 100px;
  line-height: 30px;
  color: white;
  margin-right: 5px;
  text-align: center;

}
.greenIndicator{
  background-color:green;
  width: 11px;
  height: 11px;
  display: inline-block;
  border-radius: 100px;
  line-height: 30px;
  color: white;
  margin-right: 5px;
  text-align: center;
}
.orangeIndicator{
  background-color: orange;
  width: 11px;
  height: 11px;
  display: inline-block;
  border-radius: 100px;
  line-height: 30px;
  color: white;
  margin-right: 5px;
  text-align: center;
}
.attachment_icon{
  img{
    width: 27px;
  margin-left: 23px;
  }
}