    .seqrops-pagination-wrapper{
    display: flex;

    .seqrops-pageNo-section{
        justify-content: center;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
  
}
