.highPriorityTickets {
  background-color: #f8e9ea;
  padding: 15px;
  border: 1px solid #dbd3d3 !important;
  border-radius: 10px;
  h3 {
    color: #46466e;
    font-weight: bold;
    font-size: 22px;
  }
  a {
    text-decoration: none;
  }
  span {
    background-color: #bb404c;
    width: 27px;
    display: inline-block;
    border-radius: 100px;
    line-height: 27px;
    color: white;
    margin-right: 5px;
    text-align: center;
    font-weight: 400;
    font-size: 15px;
  }
  img {
    width: 125px;
    margin-right: 5px;
    // @media only screen and (max-width: 1199px) {
    //   width: 89px;
    // }
  }
}

.overdueTickets {
  background-color: #e8f7ff;
  padding: 15px;
  border: 1px solid #c1e6ff !important;
  border-radius: 10px;
  width: 100%;
  a {
    text-decoration: none;
  }
  h3 {
    color: #46466e;
    font-weight: bold;
    font-size: 22px;
  }
  img {
    width: 125px;
    margin-right: 5px;
  }
}
.high-Priority-count {
  background-color: #fff;
  border: 1px solid #f2cdcd;
  padding: 6px;
  border-radius: 32px;

  h6 {
    margin: 0;
    color: #363f5e;
    font-size: 14px;
    padding-left: 10px;
  }
}
.overdue-count {
  background-color: white;
  border-radius: 32px;
  padding: 6px;
  border: 1px solid #c1e6ff;
  h6 {
    margin: 0;
    color: #363f5e;
    font-size: 14px;
  }
  span {
    background-color: #e8f7ff;
    margin-left: 5px;

    // width: 30px;
    // height: 30px;
    // display: inline-block;
    // border-radius: 100px;
    // line-height: 30px;
    // color: #2492dd;
    // margin-right: 5px;
    // text-align: center;
    width: 27px;
    display: inline-block;
    border-radius: 100px;
    line-height: 27px;
    color: #2492dd;
    margin-right: 5px;
    text-align: center;
    font-weight: 400;
    font-size: 15px;
  }
}
.w-85 {
  width: 85% !important;
}
.prorityCount {
  margin: auto;
  border-left: 1px solid #d3e1ec;
  padding-left: 11px;

  p{
    display: flex;
    margin: 0;
    line-height: 40px;
    span {
      background-color: #2492dd;
      width: 30px;
      height: 30px;
      display: inline-block;
      border-radius: 100px;
      line-height: 30px;
      color: white;
      margin-right: 5px;
      text-align: center;
      font-size: 14px;
    }
    span.high {
      background-color: #bb404c;
      
    }
    span.medium {
      background-color: #ffab07;
    }
  }
}
.heading_tickets {
  color: #6b7a99;
  font-weight: bold;
}
.seqrops-instance-table-wrapper {
  height: calc(100vh - 395px);
  overflow: auto;

  table {
    background-color: white;
    border: 1px solid #eee;
    tr {
      border-bottom: 1px solid #eee;
    }
    th {
      border: transparent;
      background: #f8fcff;
    }
  }
}

.middle-layer {
  button {
    border-radius: 1px solid #60abfb;
    // padding: 10px;
    background-color: #60abfb;
    // height: 42px;
    color: white;
    border-color: #60abfb;
    img {
      width: 27px;
    }
  }
}

.middle-layer {
  a {
    text-decoration: none;
  }
}

.Filter {
  h5 {
    padding-top: 10px;
    font-size: 15px;
  }
  img {
    margin-right: 10px;
  }
}

.overdue-count {
  h6 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.filterBox {
  width: 200px;
  height: 70px;
  border: 1px solid #ccc;
  padding: 10px;
}
.Filetr-button {
  background-color: transparent !important;
  color: black !important;
  border-color: transparent !important;
}
