.SecondLayer {
  background-color: #e8f7ff;
  border: 1px solid #d4e9f4 !important;
  border-radius: 10px;
  padding: 15px;
  color: #363f5e;
}

.redIndicator {
  background-color: red;
  width: 11px;
  height: 11px;
  display: inline-block;
  border-radius: 100px;
  line-height: 30px;
  color: white;
  margin-right: 5px;
  text-align: center;
}
.greenIndicator {
  background-color: green;
  width: 11px;
  height: 11px;
  display: inline-block;
  border-radius: 100px;
  line-height: 30px;
  color: white;
  margin-right: 5px;
  text-align: center;
}
.orangeIndicator {
  background-color: orange;
  width: 11px;
  height: 11px;
  display: inline-block;
  border-radius: 100px;
  line-height: 30px;
  color: white;
  margin-right: 5px;
  text-align: center;
}
.thirdLayerForm {
  width: 67%;
}
.description {
  width: 66%;
  margin-right: 10px;
  textarea {
    height: 142px;
    resize: none;
  }
}

.imgPreview {
  img {
    border-radius: 4px;
  }
}
.headerSection {
  button {
    width: 137px;
  }
}
.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popupImage {
  max-width: 100%;
  max-height: 100%;
}
.readonly {
  background-color: #e9ecef;
  pointer-events: none;
}
span.state_list {
  width: 158px;
}
.p-dialog-header {
  padding: 0.5rem !important;
}
.p-dialog-content {
  img {
    width: 100% !important;
    height: calc(100vh - 375px) !important;
    object-fit: fill !important;
  }
}

.service-commments-listing-section {
  .comment-heading-section {
    font-size: 18px;
    font-weight: bold;
    color: #363f5e;
  }

  .save-btn{
  padding: 7px 33px;

  }

  .cancel-btn{
    margin-left: -4px;
  }
  button{
    padding: 7px 27px;
  }
}

.comment-input-section {
  button {
    margin: 17px;
  }
}


.comment-list-item-wrapper {
  width: 70vw;
}

.error-message-section {
  font-size: 14px;
  color: red;
}

.noAttachment {
  margin-bottom: 22px;
  margin-top: 25px;
}
